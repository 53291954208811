import toRem from '../../helpers/theme/convertPxToRem'

const Style = ({ theme, imageWidth, imageHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        height: '100vh',
        alignItems: 'center'
    },
    '.ErrorPage': {
        '&_imageContainer': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: toRem(40),
            [theme.breakpoints.up('md')]: {
                maxWidth: toRem(imageWidth),
                height: toRem(imageHeight),
                marginBottom: 'unset'
            }
        },
        '&_content': {},
        '&Content_title': {
            fontWeight: theme.typography.fontWeight.black
        },
        '&Content_lead': {
            marginTop: toRem(30),
            fontSize: toRem(16),
            lineHeight: 1.6,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(22),
                lineHeight: 1.8
            }
        },
        '&Content_buttons': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: toRem(25),
            [theme.breakpoints.up('md')]: {
                justifyContent: 'unset',
                marginTop: toRem(40)
            },
            '.searchBtn': {
                marginRight: toRem(20)
            },
            '.searchBtn, .homepageBtn': {
                marginBottom: toRem(10)
            }
        }
    }
})

export default Style
