/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

const ChevronLeft = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.536 9.264a.9.9 0 010 1.272L14.173 15.9l5.363 5.364a.9.9 0 11-1.272 1.272l-6-6a.9.9 0 010-1.272l6-6a.9.9 0 011.272 0z"
        fill="#141313"
    />,
    'ChevronLeft'
)

export default ChevronLeft
