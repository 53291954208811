/* eslint-disable max-len */
import { createSvgIcon } from '../utils'

// SVG - 32 width, 32 height

const Home = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.829 6.47a2.025 2.025 0 012.592 0l8.1 6.75c.462.384.729.954.729 1.555v9.144a2.025 2.025 0 01-2.025 2.026h-16.2A2.025 2.025 0 016 23.919v-9.144c0-.601.267-1.17.729-1.556l8.1-6.75zm1.296 1.555l-8.1 6.75v9.144h4.05v-3.543c0-1.119.907-2.025 2.025-2.025h4.05c1.118 0 2.025.906 2.025 2.025v3.543h4.05v-9.144l-8.1-6.75zm2.025 15.894v-3.543H14.1v3.543h4.05z"
    />,
    'Home'
)

export default Home
