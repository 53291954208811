import { toRem } from '../../helpers/theme'

const style = ({ theme, isSearchOpen, isHidden }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
    padding: toRem(20),
    width: '100%',
    zIndex: theme.zIndex.navigation.inPage,
    pointerEvents: 'none',
    opacity: 1,
    transform: 'translate(0, 0%)',
    transition: 'opacity 0.25s ease-out, transform 0.25s ease-out',
    ...(isHidden && {
        opacity: 0,
        transform: 'translateY(-100%)',
        transition: 'opacity 0.25s ease-in, transform 0.25s ease-in'
    }),
    [theme.breakpoints.up('lg')]: {
        display: 'none'
    },
    '@media print': {
        display: 'none'
    },
    '& .topBarNav': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        pointerEvents: 'none',
        '&_left': {
            pointerEvents: 'auto',
            marginRight: toRem(10)
        },
        '&_right': {
            flexShrink: 0,
            pointerEvents: 'auto',
            marginLeft: 'auto'
        },
        '&_logo': {
            marginTop: toRem(4),
            marginBottom: toRem(4)
        },
        '&_backBtn': {
            minHeight: 0,
            width: toRem(44),
            height: toRem(44),
            marginTop: toRem(3),
            marginBottom: toRem(3),
            '& .btn_icon': {
                fontSize: toRem(28)
            }
        },
        '&_search': {
            '&ToggleBtn': {
                marginLeft: toRem(10),
                minHeight: 0,
                width: toRem(50),
                height: toRem(50),
                position: 'relative',
                zIndex: theme.zIndex.over,
                '& .btn_icon': {
                    fontSize: toRem(24)
                }
            },
            '&Wrap': {
                position: 'absolute',
                right: 0,
                top: '50%',
                zIndex: 0,
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
                ...(isSearchOpen && {
                    zIndex: theme.zIndex.over + 1,
                    pointerEvents: 'auto'
                }),
                [theme.breakpoints.up('560')]: {
                    maxWidth: toRem(335)
                }
            }
        },
        '&_collection': {
            '&ToggleBtn': {
                marginLeft: toRem(10),
                minHeight: 0,
                width: toRem(50),
                height: toRem(50)
            }
        }
    }
})

export default style

const motionVariants = () => ({
    open: {
        opacity: 1,
        width: '100%',
        visibility: 'visible',
        overflow: 'visible'
    },
    closed: {
        opacity: 0,
        width: toRem(120), // to avoid window x scroll and other unwanted side effects
        transitionEnd: {
            visibility: 'hidden',
            overflow: 'hidden'
        }
    },
    transition: {
        duration: 0.25,
        visibility: 'visible',
        ease: [0.04, 0.62, 0.23, 0.98]
    }
})

export { motionVariants }
