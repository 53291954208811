import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { useOutsideClick } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../Button'
import { withErrorBoundary } from '../ErrorBoundary'
import { ChevronLeftIcon, HeartFillIcon, HeartOutlineIcon, Icon, SearchIcon } from '../Icon'
import { Logo } from '../Logo'
import styles, { motionVariants } from './TopBar.style'

const TopBarStyled = styled.nav(props => ({ ...styles(props) }))

const TopBar = ({
    disabled,
    logoHref,
    logoHrefAs,
    logoTitle,
    backButtonHref,
    backButtonHrefAs,
    searchComponent: SearchComponent,
    isHidden,
    onCollectionButtonClick,
    isCollectionButtonActive,
    className,
    ...rest
}) => {
    const [searchOpen, setSearchOpen] = useState(false)

    const searchRef = useRef()
    const { back } = useRouter()
    const [isLogo, setIsLogo] = useState(true)

    const onBackButtonClick = () => {
        back()
    }

    useEffect(() => {
        if (process.browser) {
            if (window.sessionStorage.getItem('ClickingIndicator')) {
                setIsLogo(false)
            } else {
                window.sessionStorage.setItem('ClickingIndicator', 1)
            }
        }
    }, [])

    useOutsideClick(searchRef, () => setSearchOpen(false))

    const contentRightCheck =
        SearchComponent || (onCollectionButtonClick && typeof onCollectionButtonClick === 'function')

    if (disabled) {
        return null
    }

    return (
        <TopBarStyled isSearchOpen={searchOpen} isHidden={isHidden} className={className} {...rest}>
            <div className="topBarNav">
                {(isLogo || backButtonHref || typeof onBackButtonClick === 'function') && (
                    <div className="topBarNav_left">
                        {isLogo && (
                            <Logo
                                href={logoHref}
                                hrefAs={logoHrefAs}
                                hrefTitle={logoTitle}
                                rounded
                                className="topBarNav_logo"
                            />
                        )}
                        {!isLogo && (backButtonHref || typeof onBackButtonClick === 'function') && (
                            <Button
                                className="topBarNav_backBtn"
                                title="Nazad"
                                variant={buttonVariants.SECONDARY}
                                size={buttonSizes.NORMAL}
                                href={backButtonHref}
                                hrefAs={backButtonHrefAs}
                                onClick={onBackButtonClick}
                                disableBorder
                                isIconOnly
                                iconLeftComponent={<Icon icon={ChevronLeftIcon} />}
                                hasShadow
                            />
                        )}
                    </div>
                )}
                {contentRightCheck && (
                    <div className="topBarNav_right" ref={searchRef}>
                        {onCollectionButtonClick && typeof onCollectionButtonClick === 'function' && (
                            <Button
                                className="topBarNav_collectionToggleBtn"
                                title={isCollectionButtonActive ? 'Makni iz kolekcije' : 'Dodaj u kolekciju'}
                                variant={isCollectionButtonActive ? buttonVariants.PRIMARY : buttonVariants.SECONDARY}
                                size={buttonSizes.NORMAL}
                                onClick={onCollectionButtonClick}
                                isIconOnly
                                disableBorder
                                hasShadow>
                                {isCollectionButtonActive ? (
                                    <Icon icon={HeartFillIcon} size={32} color="#FFFFFF" />
                                ) : (
                                    <Icon icon={HeartOutlineIcon} size={32} />
                                )}
                            </Button>
                        )}
                        {SearchComponent && (
                            <>
                                <Button
                                    className="topBarNav_searchToggleBtn"
                                    title="Pretraga"
                                    isIconOnly
                                    disableBorder
                                    hasShadow={!searchOpen}
                                    iconRightComponent={<Icon icon={SearchIcon} />}
                                    size={buttonSizes.LARGER}
                                    variant={buttonVariants.SECONDARY}
                                    onClick={() => setSearchOpen(true)}
                                />
                                <motion.div
                                    initial="closed"
                                    animate={searchOpen ? 'open' : 'closed'}
                                    transition="transition"
                                    variants={motionVariants()}
                                    className="topBarNav_searchWrap">
                                    <SearchComponent />
                                </motion.div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </TopBarStyled>
    )
}

TopBar.propTypes = {
    disabled: PropTypes.bool,
    logoHref: PropTypes.string,
    logoHrefAs: PropTypes.string,
    logoTitle: PropTypes.string,
    backButtonHref: PropTypes.string,
    backButtonHrefAs: PropTypes.string,
    searchComponent: PropTypes.elementType,
    isHidden: PropTypes.bool,
    onCollectionButtonClick: PropTypes.func,
    isCollectionButtonActive: PropTypes.bool,
    className: PropTypes.string
}

TopBar.defaultProps = {
    disabled: false,
    logoHref: '/',
    logoHrefAs: undefined,
    logoTitle: 'Naslovnica',
    backButtonHref: undefined,
    backButtonHrefAs: undefined,
    searchComponent: undefined,
    isHidden: undefined,
    onCollectionButtonClick: undefined,
    isCollectionButtonActive: false,
    className: undefined
}

export default withErrorBoundary(TopBar)
