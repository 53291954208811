import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { FiltersActions, SearchActions } from '@hmn/coolinarika-web-core/actions'
import { md, useBreakpoints } from '@hmn/coolinarika-web-core/hooks'

import { Button, buttonSizes, buttonVariants } from '../Button'
import { withErrorBoundary } from '../ErrorBoundary'
import { Highlight, highlightSizes, highlightVariants } from '../Highlight'
import { HtmlContent } from '../HtmlContent'
import { HomeIcon, Icon, SearchIcon } from '../Icon'
import { Image } from '../Image'
import { NavigationTopBar } from '../Navigation'
import styles from './ErrorPage.style'

const ErrorPageStyled = styled.div(props => ({ ...styles(props) }))

const ErrorPage = ({
    title,
    titleTagComponent: TitleTag,
    lead,
    imageId,
    imageWidth,
    imageHeight,
    placeholderImageSrc,
    className,
    buttonHomepageTitle,
    buttonHomepageLink,
    buttonHomepageAction,
    buttonSearchTitle,
    disableSearchButton,
    ...rest
}) => {
    const dispatch = useDispatch()
    const [mediaQueryMedium] = useBreakpoints(md)

    const handleSearchOpen = useCallback(() => {
        dispatch(SearchActions.toggle())
        if (mediaQueryMedium) {
            dispatch(FiltersActions.toggle())
        }
    }, [dispatch, mediaQueryMedium])

    if (!title) {
        return null
    }

    return (
        <ErrorPageStyled imageWidth={imageWidth} imageHeight={imageHeight} className={className} {...rest}>
            <NavigationTopBar />

            <div className="ErrorPage_imageContainer">
                <Image image={placeholderImageSrc} alt={title} height={imageHeight} width={imageWidth} />
            </div>

            <div className="ErrorPage_content">
                {title && (
                    <Highlight
                        className="ErrorPageContent_title"
                        size={highlightSizes.XLARGE}
                        variant={highlightVariants.PRIMARY}
                        TagComponent={TitleTag}>
                        {title}
                    </Highlight>
                )}

                {lead && <HtmlContent content={lead} className="ErrorPageContent_lead" wrapperComponent="div" />}

                {((!disableSearchButton && buttonSearchTitle) || buttonHomepageTitle) && (
                    <div className="ErrorPageContent_buttons">
                        {!disableSearchButton && buttonSearchTitle && (
                            <Button
                                title={buttonSearchTitle}
                                onClick={handleSearchOpen}
                                className="searchBtn"
                                size={buttonSizes.LARGE}
                                sizeMobile={buttonSizes.NORMAL}
                                variant={buttonVariants.SECONDARY}
                                iconRightComponent={<Icon icon={SearchIcon} />}>
                                {buttonSearchTitle}
                            </Button>
                        )}

                        {buttonHomepageTitle && (
                            <Button
                                title={buttonHomepageTitle}
                                href={buttonHomepageLink}
                                onClick={buttonHomepageAction}
                                className="homepageBtn"
                                size={buttonSizes.LARGE}
                                sizeMobile={buttonSizes.NORMAL}
                                variant={buttonVariants.SECONDARY}
                                iconRightComponent={<Icon icon={HomeIcon} />}>
                                {buttonHomepageTitle}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </ErrorPageStyled>
    )
}

ErrorPage.propTypes = {
    imageId: PropTypes.string,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholderImageSrc: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    disableSearchButton: PropTypes.bool,
    buttonSearchTitle: PropTypes.string,
    buttonHomepageLink: PropTypes.string,
    buttonHomepageTitle: PropTypes.string,
    buttonHomepageAction: PropTypes.func,
    className: PropTypes.string
}

ErrorPage.defaultProps = {
    imageId: undefined,
    imageWidth: 599,
    imageHeight: 406,
    placeholderImageSrc: '/images/placeholder/page-not-found.svg',
    title: undefined,
    titleTagComponent: 'h2',
    lead: undefined,
    buttonSearchTitle: 'Pretraži',
    disableSearchButton: false,
    buttonHomepageLink: '/',
    buttonHomepageTitle: 'Početna',
    buttonHomepageAction: () => {},
    className: undefined
}

export default withErrorBoundary(ErrorPage)
